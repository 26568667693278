<template>
  <TransitionRoot
    :show="searchOpen"
    class="fixed left-0 top-0 flex w-full items-center gap-4 bg-charcoal-100 p-4 py-12 md:py-20"
    enter="transform transition duration-300 ease-out"
    enter-from="translate-y-[-500px]"
    enter-to="translate-y-0"
    leave="transform transition duration-150 ease-out"
    leave-from="translate-y-0"
    leave-to="translate-y-[-500px]"
  >
    <input
      id="search"
      v-model="searchParams"
      type="search"
      autocomplete="off"
      placeholder="Search"
      class="block w-full rounded-sm border-0 border-b-2 border-white/50 bg-transparent px-4 py-2 text-white !outline-none !ring-0 placeholder:text-white/50 focus:border-white"
      @keyup.enter="toggleSearch"
    />

    <button
      type="button"
      class="block"
      @click="toggleSearch"
    >
      <SearchIcon
        class="size-8 *:!fill-white"
        aria-hidden="true"
      />
    </button>

    <button
      type="button"
      class="block"
      @click="closeSearch"
    >
      <CloseIcon
        class="size-8 *:stroke-white"
        aria-hidden="true"
      />
    </button>
  </TransitionRoot>

  <button
    type="button"
    class="block"
    @click="toggleSearch"
  >
    <SearchIcon
      class="size-8"
      :class="[
        shrink ? '*:!fill-charcoal-100' : '',
        overlay ? '*:!fill-white' : '',
        route.name === 'index' || route.name === 'whats-on-uid' ? '*:fill-white' : '*:fill-charcoal-100',
      ]"
      aria-hidden="true"
    />
  </button>
</template>

<script lang="ts" setup>
import { TransitionRoot } from "@headlessui/vue";

import SearchIcon from "~/assets/svgs/search.svg";
import CloseIcon from "~/assets/svgs/cross.svg";

const route = useRoute();
const router = useRouter();

const props = defineProps<{
  overlay?: boolean;
  shrink?: boolean;
}>();

const searchOpen = ref(false);
const searchParams = ref("");

const toggleSearch = () => {
  if (!searchOpen.value) {
    searchOpen.value = true;
  } else {
    searchOpen.value = false;
    if (searchParams.value != "") {
      router.push({ path: `/search/${searchParams.value}` });
    }
  }
};

const closeSearch = () => {
  searchOpen.value = false;
};
</script>
