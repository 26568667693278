<template>
  <UINewsletter />

  <div class="grid md:grid-cols-4">
    <!-- Socials -->
    <div class="grid content-start bg-blue-50 p-10 text-charcoal-100 md:col-span-2 md:grid-cols-1 xl:col-span-1">
      <h2 class="mb-10 text-header2 md:text-desktop-header1">
        Connect
      </h2>
      <div>
        <div
          v-for="(item, index) in settings?.data.social_links"
          :key="item.label || index"
        >
          <NuxtLink
            v-if="item.link.link_type != 'Any'"
            :to="prismic.asLink(item.link) ?? ''"
            class="block"
          >
            {{ item.label }}
          </NuxtLink>
        </div>
      </div>
    </div>

    <!-- Info -->
    <div class="grid content-start bg-rust-50 p-10 text-charcoal-100 md:col-span-2 md:grid-cols-1 xl:col-span-1">
      <h2 class="mb-10 text-header2 md:text-desktop-header1">
        Info
      </h2>
      <div>
        <div
          v-for="(item, index) in navigation?.data.footer"
          :key="item.label || index"
        >
          <NuxtLink
            v-if="item.link.link_type != 'Any'"
            :to="prismic.asLink(item.link) ?? ''"
            class="block"
          >
            {{ item.label }}
          </NuxtLink>
        </div>
      </div>
    </div>

    <!-- Contacts -->
    <div class="grid content-start bg-green-50 p-10 text-charcoal-100 md:col-span-4 md:grid-cols-1 xl:col-span-2">
      <h2 class="mb-10 text-header2 md:text-desktop-header1">
        Contact
      </h2>

      <div class="grid grid-cols-1 gap-10 md:grid-cols-2 ">
        <div>
          <PrismicRichText :field="settings?.data.address" />
          <NuxtLink to="/contact">
            For more information:
          </NuxtLink>
          <a
            :href="`mailto:${settings?.data.email}`"
            target="_blank"
            class="block"
          >
            {{ settings?.data.email }}
          </a>
          <a
            :href="`tel:${settings?.data.phone}`"
            target="_blank"
            class="block"
          >
            {{ settings?.data.phone }}
          </a>
        </div>
        <div>
          <PrismicRichText :field="settings?.data.closed_on" />
          <PrismicRichText :field="settings?.data.ground_floor" />
          <PrismicRichText :field="settings?.data.other_floors" />
        </div>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <div class="items-start justify-between bg-white p-10 md:flex">
    <NuxtLink to="/">
      <div class="z-30 text-logo leading-[.8] text-charcoal-100 transition-all md:text-tablet-logo">
        LIGHTBOX<br />GALLERY
      </div>
    </NuxtLink>

    <div class="flex flex-col text-right">
      <p class="order-1 mt-10 text-left md:mt-0 md:text-right">
        © {{ new Date().getFullYear() }} | {{ settings?.data.copyright }}
      </p>

      <div class="items-center justify-between gap-10 md:order-1 md:flex">
        <img
          src="~/assets/logos/arts_council.png"
          class="mt-10"
        />
        <img
          src="~/assets/logos/woking_bc.png"
          class="mt-10"
        />
        <img
          src="~/assets/logos/heritage_fund.png"
          class="mt-10"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const prismic = usePrismic();

const { data: navigation } = useAsyncData("navigation", () =>
  prismic.client.getSingle("navigation"),
);

const { data: settings } = useAsyncData("settings", () =>
  prismic.client.getSingle("settings"),
);
</script>
