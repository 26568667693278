<template>
  <TransitionRoot
    :show="props.open"
    class="inset-0 bg-charcoal-100 text-white"
    enter="transform transition duration-500 ease-out"
    enter-from="translate-y-full"
    enter-to="translate-y-0"
    leave="transform transition duration-100 ease-out"
    leave-from="translate-y-0"
    leave-to="translate-y-full"
  >
    <nav
      class="mx-auto flex items-start justify-between p-10 text-white lg:h-1/5"
      aria-label="Global Navigation"
    >
      <!-- Logo -->
      <NuxtLink to="/">
        <div class="text-logo md:text-tablet-logo lg:text-desktop-logo">
          LIGHTBOX<br />GALLERY
        </div>
      </NuxtLink>

      <div class="relative z-30 flex gap-4">
        <!-- Donate Button -->
        <a
          href="/donations"
          class="hidden rounded-md border border-white bg-white px-3.5 py-1.5 text-center text-body-copy text-charcoal-100 transition-colors hover:border-charcoal-50 hover:bg-charcoal-50 md:block"
        >Donate</a>

        <!-- Search -->
        <SearchForm :overlay="open" />

        <!-- Close Icon -->
        <button
          type="button"
          @click="emits('toggleMenu')"
        >
          <CloseIcon
            class="size-8 *:stroke-white"
            aria-hidden="true"
          />
        </button>
      </div>
    </nav>

    <div class="h-full w-screen overflow-y-scroll pb-40 md:pb-0">
      <div class="content-center gap-4 px-10 py-6 lg:grid lg:h-3/5 lg:grid-cols-3">
        <!-- Main Menu -->
        <div class="menu mb-10 content-start md:mb-0">
          <div
            v-for="(item, index) in navigation?.data.main"
            :key="item.label || index"
          >
            <NuxtLink
              v-if="item.link.link_type != 'Any'"
              :to="prismic.asLink(item.link) ?? ''"
              class="link mb-4 block text-hero-copy md:mb-8 md:text-desktop-hero-copy"
              @click="emits('toggleMenu')"
            >
              {{ item.label }}
            </NuxtLink>
          </div>
        </div>

        <!-- Secondary Menu -->
        <div class="menu mb-10 content-start md:mb-0">
          <div
            v-for="(item, index) in navigation?.data.secondary"
            :key="item.label || index"
          >
            <NuxtLink
              v-if="item.link.link_type != 'Any'"
              :to="prismic.asLink(item.link) ?? ''"
              class="link mb-2 block text-large-copy md:mb-4 md:text-desktop-large-copy"
              @click="emits('toggleMenu')"
            >
              {{ item.label }}
            </NuxtLink>
          </div>
        </div>

        <a
          href="/donations"
          class="mb-10 block rounded-md border border-white bg-white px-3.5 py-1.5 text-center text-body-copy text-charcoal-100 transition-colors hover:border-charcoal-50 hover:bg-charcoal-50 md:hidden"
        >Donate</a>

        <!-- Social Links -->
        <div class="menu mb-10 content-start md:mb-0">
          <div
            v-for="(item, index) in settings?.data.social_links"
            :key="item.label || index"
          >
            <NuxtLink
              v-if="item.link.link_type != 'Any'"
              :to="prismic.asLink(item.link) ?? ''"
              class="link mb-2 block text-large-copy md:mb-4 md:text-desktop-large-copy"
              target="_blank"
              @click="emits('toggleMenu')"
            >
              {{ item.label }}
            </NuxtLink>
          </div>

          <div class="menu mt-10 md:mb-0">
            For more information:
            <a
              :href="`mailto:${settings?.data.email}`"
              target="_blank"
              class="link block"
              @click="emits('toggleMenu')"
            >{{
              settings?.data.email }}</a>
            <a
              :href="`tel:${settings?.data.phone}`"
              target="_blank"
              class="link block"
              @click="emits('toggleMenu')"
            >{{
              settings?.data.phone }}</a>
          </div>
        </div>
      </div>

      <div class="content-center gap-4 px-10 py-6 lg:grid lg:h-1/5 lg:grid-cols-6">
        <div class="menu mb-10 content-start md:mb-0">
          <PrismicRichText :field="settings?.data.address" />
        </div>
        <div />
        <div class="menu mb-10 content-start md:mb-0">
          <PrismicRichText :field="settings?.data.closed_on" />
        </div>

        <div class="menu mb-10 content-start md:mb-0">
          <PrismicRichText :field="settings?.data.ground_floor" />
        </div>

        <div class="menu mb-10 content-start md:mb-0">
          <PrismicRichText :field="settings?.data.other_floors" />
        </div>
      </div>
    </div>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { TransitionRoot } from "@headlessui/vue";
import { gsap } from "gsap";
import CloseIcon from "~/assets/svgs/cross.svg";

const prismic = usePrismic();
const emits = defineEmits(["toggleMenu"]);

const props = defineProps<{
  open: boolean;
}>();

const { data: navigation } = useAsyncData("navigation", () =>
  prismic.client.getSingle("navigation"),
);
const { data: settings } = useAsyncData("settings", () =>
  prismic.client.getSingle("settings"),
);

watch(
  () => props.open, async (val) => {
    if (val) {
      await nextTick();
      gsap.from(".menu", { y: 50, opacity: 0, duration: 1, stagger: 0.2 });
    }
  },
);
</script>
